.navbar-right {
    margin-right: 10px;
}

.section-button {
    width: 50%;
    margin-left: 25%;
    margin-right: 50%;
    border-width: 2px;
}

.form-section .form-group {
    padding: 10px;
}

.form-section .form-label {
    padding-right: 5px;
}

.a-required {
    color: red;
}